<template>
  <section class="new-real-estate">
    <section class="hero">
      <div class="hero-container">
        <div class="hero-container-title">
          <h1>Découvrez tous les atouts de l’immobilier neuf</h1>
        </div>
        <div class="hero-container-img">
          <img
            src="@/assets/img/pages/new-real-estate-1.jpg"
            alt="Découvrez tous les atouts de l’immobilier neuf"
          />
        </div>
      </div>
    </section>
    <article class="article">
      <section class="fit-content card">
        <div class="smartpage-card smartpage-card-reverse">
          <div class="smartpage-card-content">
            <div>
              <h2>Un habitat qui vous ressemble <br> au service de la qualité de vie</h2>
              <p>
                Les maisons neuves présentent des atouts très attractifs pour tous les ménages
                d’aujourd’hui : de nombreuses possibilités d’aménagements pour répondre à tous les
                besoins. Pratique, bien agencées et équipées, elles affichent un formidable confort
                intérieur comme extérieur, adaptées aux modes de vies modernes mais authentiques, avec
                la création de lieux de partage familial et social… Un projet de vie, un projet pour la
                vie !
              </p>
            </div>
          </div>
          <div class="smartpage-card-img">
            <img src="@/assets/img/pages/new-real-estate-4.jpg" alt="Un habitat qui vous ressemble au service de la qualité de vie">
          </div>
        </div>
      </section>
      <section>
        <div class="smartpage-costing">
          <div class="smartpage-costing-content">
            <div>
              <h2>Configurez et chiffrez votre projet en moins de 2 minutes !</h2>
              <router-link class="costing-btn" :to="{ name: 'home', hash: '#hero' }">Commencer mon projet</router-link>
            </div>
            <div>
              <div>
                <img src="@/assets/img/chrono.svg" alt="chrono icon" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="fit-content card">
        <div class="smartpage-card">
          <div class="smartpage-card-content">
            <div>
              <h2>Des normes techniques <br>actuelles et des frais minorés</h2>
              <p>
                Comme tout l’immobilier neuf, la maison neuve est soumise aux réglementations en vigueur
                et offre ainsi de sérieux avantages en termes de qualité et d’économies d’énergies. Elle
                bénéficie des normes techniques garantissant des performances énergétiques sur les
                aspects thermiques et sur l’isolation du logement. Des économies, du confort, et une
                posture environnementale…
              </p>
            </div>
          </div>
          <div class="smartpage-card-img">
            <img src="@/assets/img/pages/new-real-estate-3.jpg" alt="Des normes techniques actuelles et des frais minorés">
          </div>
        </div>
      </section>
      <section class="fit-content card">
        <div class="smartpage-card smartpage-card-reverse">
          <div class="smartpage-card-content">
            <div>
              <h2>Les nombreuses garanties <br> de l’immobilier neuf</h2>
              <p>
                En achetant une maison neuve avec un constructeur de maisons individuelles, vous serez
                protégé par le CCMI, le Contrat de Construction de Maison Individuelle, qui vous
                garantit une construction achevée à des prix et des délais convenus. Ce contrat,
                institué par la loi du 19 décembre 1990, inclut de nombreuses garanties et assurances
                comme la garantie décennale, la garantie de bon fonctionnement, l’assurance
                dommage-ouvrage…
              </p>
            </div>
          </div>
          <div class="smartpage-card-img">
            <img src="@/assets/img/pages/new-real-estate-4.jpg" alt="Les nombreuses garanties de l’immobilier neuf">
          </div>
        </div>
      </section>
    </article>
  </section>
</template>

<script>

export default {
  name: 'new-real-estate',
};
</script>

<style lang="sass">
.new-real-estate
  background-color: $medium-bg
  padding-bottom: 6rem

  .hero
    @include smartpage-hero
  .article
    margin-bottom: 14rem
    .card + .card
      margin-top: 20rem
    section
      margin-top: 8rem
      &:first-child
        margin-top: 25rem
      .smartpage-card
        @include smartpage-card
      .smartpage-card-reverse
        @include smartpage-card-reverse
      .smartpage-costing
        @include smartpage-costing

  .fit-content
    width: 90%
    max-width: 1180px
    margin: 0 auto

  @media (max-width: 1024px)
    .hero
      &-container
        bottom: -2.5rem

  @media (max-width: 768px)
    .article
      margin-bottom: 5rem
      section
        margin-top: 1.5rem
        &:first-child
          margin-top: 10rem
      .card + .card
        margin-top: 1.5rem
</style>
